var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "vx-row" }, [
    _c(
      "div",
      { staticClass: "vx-col w-full mb-base" },
      [
        _c(
          "vx-card",
          [
            _c("div", { staticClass: "ml-6 mt-6" }, [
              _vm.supplierName
                ? _c("h3", { staticClass: "supplier-health-heading" }, [
                    _vm._v("Supplier Health - " + _vm._s(_vm.supplierName)),
                  ])
                : _vm._e(),
            ]),
            _c("ag-grid-vue", {
              ref: "agGridTable",
              staticClass: "ag-theme-material w-100 my-4 ag-grid-table",
              attrs: {
                components: _vm.cellRendererComponents,
                columnDefs: _vm.columnDefs,
                defaultColDef: _vm.defaultColDef,
                rowData: _vm.healthData,
                gridOptions: _vm.gridOptions,
                rowSelection: "single",
                pagination: false,
              },
              on: { "selection-changed": _vm.onSelectionChanged },
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }